import React from "react";

import { ReactComponent as Plus } from "../../../assets/images/plus.svg";
import css from "./ImageUploader.module.scss";

export const ImageUploader = ({ image, handleImageChange, isLabel = true }) => {
    return (
        <>
            {isLabel ? <p className={css.label}>Фото:</p> : null}
            <div className={css.imageUploader}>
                <input
                    type="file"
                    accept="image/*"
                    onChange={handleImageChange}
                    className={css.inputFile}
                />
                <div className={css.preview}>
                    {image && <img src={image} alt="Preview" />}
                    {!image && (
                        <>
                            <p>Завантажити зображення </p> <Plus />
                        </>
                    )}
                </div>
            </div>
        </>
    );
};
