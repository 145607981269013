import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { MyInput } from "../../components/commons/MyInput/MyInput";
import { MyTextarea } from "../../components/commons/MyTextarea/MyTextarea";
import { TextEditor } from "../../components/commons/TextEditor/TextEditor";
import { ImageUploader } from "../../components/commons/ImageUploader/ImageUploader";
import { MyButton } from "../../components/commons/MyButton/MyButton";
import { GoBackBtn } from "../../components/commons/GoBackBtn/GoBackBtn";
import toastMessage from "../../components/commons/Toast/Toast";
import { validateTitle } from "../../utils/validateTitle";

import {
    createTreatment,
    getOneTreatment,
    updateTreatment,
    uploadTreatmentImage,
} from "../../services/api";

import css from "./AdminTreatment.module.scss";

const newDefaultTreatment = {
    description: "",
    image: "",
    short_description: "",
    title: "",
};

export const AdminOneTreatment = () => {
    const navigate = useNavigate();
    const { id } = useParams();
    const [selectTreatment, setSelectTreatment] = useState(null);
    const [selectedFile, setSelectedFile] = useState(null);
    const [image, setImage] = useState(selectTreatment?.image || "");
    const [isCreate, setCreate] = useState(false);

    useEffect(() => {
        if (id === "create_new") {
            setCreate(true);
            setSelectTreatment(newDefaultTreatment);
            return;
        }

        const urlId = id.split("-").join(" ");
        getOneTreatment(urlId).then(({ data }) => {
            setSelectTreatment(data);
            setImage(data.image);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleTitle = (e) => {
        const validVal = validateTitle(e.target.value);
        setSelectTreatment({
            ...selectTreatment,
            title: validVal,
        });
    };

    const handleImageChange = (e) => {
        const file = e.target.files[0];
        if (!file) return;
        setSelectedFile(file);
        setImage(URL.createObjectURL(file));
    };

    const uploadFile = async () => {
        if (selectedFile) {
            let formData = new FormData();
            formData.append("file", selectedFile);
            const result = await uploadTreatmentImage(formData);
            if (result.status === 200) return result.data.toString().trim();
        }

        return "";
    };

    const isValidData = () => {
        if (
            selectTreatment.title === "" ||
            selectTreatment.short_description === "" ||
            selectTreatment.description === ""
        ) {
            toastMessage("Заповніть усі поля.", 300);
            return false;
        }
        return true;
    };

    const handleSubmit = () => {
        if (!isValidData()) return;
        uploadFile().then((newFileName) => {
            const newData = {
                id: selectTreatment.id,
                title: selectTreatment.title,
                short_description: selectTreatment.short_description,
                description: selectTreatment.description,
                image: newFileName.length ? newFileName : selectTreatment.image,
            };

            updateTreatment(newData).then((data) => {
                if (data) {
                    toastMessage("Інформація успішно оновлена.", data.status);
                    navigate(-1);
                }
            });
        });
    };

    const handleCreate = () => {
        if (!isValidData()) return;
        uploadFile().then((newFileName) => {
            const newData = {
                title: selectTreatment.title,
                short_description: selectTreatment.short_description,
                description: selectTreatment.description,
                image: newFileName.length ? newFileName : selectTreatment.image,
            };
            createTreatment(newData).then((data) => {
                if (data) {
                    toastMessage(
                        "Нове лікування успішно створене.",
                        data.status
                    );
                    navigate(-1);
                }
            });
        });
    };

    return (
        <>
            {selectTreatment ? (
                <div className={css.wrapAdminOneTreatment}>
                    <GoBackBtn />
                    <MyInput
                        value={selectTreatment.title}
                        onChange={handleTitle}
                        label="Заголовок:"
                    />
                    <MyTextarea
                        value={selectTreatment.short_description}
                        onChange={(e) =>
                            setSelectTreatment({
                                ...selectTreatment,
                                short_description: e.target.value,
                            })
                        }
                        label="Короткий опис:"
                    />
                    <TextEditor
                        text={selectTreatment.description}
                        setText={(text) =>
                            setSelectTreatment((prevState) => ({
                                ...prevState,
                                description: text,
                            }))
                        }
                        label="Опис:"
                    />

                    <div>
                        <span>
                            <ImageUploader
                                image={image}
                                handleImageChange={handleImageChange}
                            />
                        </span>
                        <MyButton
                            text="Зберегти"
                            onClick={isCreate ? handleCreate : handleSubmit}
                        />
                    </div>
                </div>
            ) : null}
        </>
    );
};
