import React, { useState, useRef } from "react";
import Slider from "react-slick";
import { Lightbox } from "react-modal-image";

import css from "./MySlider.module.scss";

export const MySlider = ({ slides }) => {
    let sliderRef = useRef(null);
    const [modalImage, setModalImage] = useState(null);

    const settings = {
        speed: 2500,
        autoplay: true,
        autoplaySpeed: 1500,
        slidesToShow: 3,
        slidesToScroll: 1,
        dots: true,
        infinite: true,
    };

    return (
        <>
            {modalImage && (
                <div className={css.modalImage}>
                    <Lightbox
                        large={modalImage?.image}
                        alt={`${modalImage.image}${modalImage.id}`}
                        onClose={() => {
                            setModalImage(null);
                            sliderRef.slickPlay();
                        }}
                    />
                </div>
            )}
            <div className={css.wrapSlider}>
                <Slider ref={(slider) => (sliderRef = slider)} {...settings}>
                    {slides.map((slide) => (
                        <div key={slide.id} className={css.wrapImage}>
                            <img
                                onDoubleClick={() => {
                                    setModalImage(slide);
                                    sliderRef.slickPause();
                                }}
                                src={slide.image}
                                alt={`${slide.image}${slide.id}`}
                            />
                        </div>
                    ))}
                </Slider>
            </div>
        </>
    );
};
