import React, { useState, useEffect } from "react";

import { MySlider } from "../../components/commons/MySlider/MySlider";
import { getMediaImages, getMediaVideo } from "../../services/api";

import css from "./Media.module.scss";

export const Media = () => {
    const [video, setVideo] = useState(null);
    const [images, setImages] = useState(null);

    useEffect(() => {
        getMediaVideo(2).then(({ data }) => {
            setVideo(data.youtube_url);
        });
        getMediaImages().then(({ data }) => {
            setImages(data.images);
        });
    }, []);

    return (
        <div className={`${css.wrapMedia} container`}>
            {video ? <YouTubeFrame link={video} /> : null}
            {images ? <MySlider slides={images} /> : null}
        </div>
    );
};

const YouTubeFrame = ({ link }) => {
    return (
        <iframe
            title="YouTube video player"
            className="youtube-player"
            allowFullScreen=""
            type="text/html"
            src={`${link}`}
        />
    );
};

export { YouTubeFrame };
