import { createContext, useContext, useState, useEffect } from "react";
import { getContacts } from "../services/api";

const ContactsContext = createContext("");

const ContactsContextProvider = ({ children }) => {
    const [contacts, setContacts] = useState(null);

    useEffect(() => {
        getContacts().then(({ data }) => {
            setContacts(data);
        });
    }, []);

    return (
        <ContactsContext.Provider value={contacts}>
            {children}
        </ContactsContext.Provider>
    );
};

export default ContactsContextProvider;

export const useContacts = () => useContext(ContactsContext);
