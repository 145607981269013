import Header from "./HEADER/Header";
import Main from "./MAIN/Main";
import Footer from "./FOOTER/Footer";
import { ToastContainer } from "react-toastify";

import css from "./App.module.scss";

function App() {
    return (
        <div className={css.app}>
            <header className={css.header}>
                <Header />
            </header>
            <main className={css.main}>
                <Main />
            </main>
            <Footer />

            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="colored"
            />
        </div>
    );
}

export default App;
