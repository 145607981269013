import React from "react";
import { NavLink } from "react-router-dom";
import DeleteBtn from "../DeleteBtn/DeleteBtn";
import titleToLink from "../../../utils/titleToLink";
import css from "./DeletableContentCard.module.scss";

const DeletableContentCard = ({
    item = null,
    rootUrl = "/",
    handleDelete = null,
}) => {
    return (
        <NavLink
            className={css.link}
            to={`${rootUrl}${titleToLink(item.title)}`}
        >
            <li>
                <div>
                    <p>{item.title}</p>
                    <p>{item.short_description}</p>
                </div>
                <div className={css.wrapRight}>
                    <DeleteBtn
                        onClick={(e) => {
                            e.preventDefault();
                            handleDelete(item.id);
                        }}
                    />
                    <img src={item.image} alt={item.title} />
                </div>
            </li>
        </NavLink>
    );
};

export default DeletableContentCard;
