import React, { useState, useEffect } from "react";

import { MyInput } from "../../components/commons/MyInput/MyInput";
import { MyButton } from "../../components/commons/MyButton/MyButton";
import { TextEditor } from "../../components/commons/TextEditor/TextEditor";
import toastMessage from "../../components/commons/Toast/Toast";
import { updateContacts } from "../../services/api";
import { useContacts } from "../../context/ContactsContext";

import css from "./AdminContacts.module.scss";

export const AdminContacts = () => {
    const contacts = useContacts();

    const [address, setAddress] = useState("");
    const [phone, setPhone] = useState("");
    const [email, setEmail] = useState("");
    const [facebookLink, setFacebookLink] = useState("");
    const [instagramLink, setInstagramLink] = useState("");
    const [busTimetable, setBusTimetable] = useState(null);

    useEffect(() => {
        if (contacts) {
            setAddress(contacts.address);
            setPhone(contacts.phone);
            setEmail(contacts.email);
            setFacebookLink(contacts.facebook_link);
            setInstagramLink(contacts.instagram_link);
            setBusTimetable(contacts.bus_timetable);
        }
    }, [contacts]);

    const handleSubmit = async () => {
        const data = {
            address,
            phone,
            email,
            facebook_link: facebookLink,
            instagram_link: instagramLink,
            bus_timetable: busTimetable,
        };

        updateContacts(data)
            .then((data) => {
                if (data) {
                    toastMessage("Дані збережені успішно.", data.status);
                }
            })
            .catch((e) => console.log(e, "error"));
    };

    return (
        <div className={css.wrapAdminContacts}>
            <div className={css.address}>
                <MyInput
                    value={address}
                    onChange={(e) => setAddress(e.target.value)}
                    label="Aдреса:"
                />
                <MyInput
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                    label="Телефон:"
                />
                <MyInput
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    label="Емейл:"
                />
                <MyInput
                    value={facebookLink}
                    onChange={(e) => setFacebookLink(e.target.value)}
                    label="Facebook лінк:"
                />
                <MyInput
                    value={instagramLink}
                    onChange={(e) => setInstagramLink(e.target.value)}
                    label="Instagram лінк:"
                />
                {busTimetable ? (
                    <TextEditor
                        text={busTimetable}
                        setText={setBusTimetable}
                        label="Графік руху автобуса:"
                    />
                ) : null}
            </div>
            <div>
                <MyButton text="Зберегти" onClick={handleSubmit} />
            </div>
        </div>
    );
};
