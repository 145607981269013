import React from "react";
import { ReactComponent as Facebook } from "../../assets/images/icons/facebook.svg";
import { ReactComponent as Instagram } from "../../assets/images/icons/instagram.svg";
import { ReactComponent as Location } from "../../assets/images/icons/location.svg";
import { useContacts } from "../../context/ContactsContext";

import css from "./Footer.module.scss";

const Footer = () => {
    const contacts = useContacts();

    return (
        <>
            {contacts ? (
                <footer className={css.footer}>
                    <a
                        title="Подзвонити"
                        href={`tel:+${contacts?.phone.split(" ")}`}
                    >
                        {contacts?.phone}
                    </a>
                    <a title="Написати лист" href={`mailto:${contacts?.email}`}>
                        {contacts?.email}
                    </a>

                    <span className={css.media}>
                        знайдіть нас у
                        <a
                            href={contacts?.facebook_link}
                            target="_blank"
                            rel="noopener noreferrer"
                            title="Відвідати нас у Facebook"
                        >
                            <Facebook />
                        </a>
                        <a
                            href={contacts?.instagram_link}
                            target="_blank"
                            rel="noopener noreferrer"
                            title="Відвідати нас в Instagram"
                        >
                            <Instagram />
                        </a>
                    </span>

                    <a
                        href="https://www.google.com/maps/place/%D0%A1%D0%B0%D0%BD%D0%B0%D1%82%D0%BE%D1%80%D1%96%D0%B9+%22%D0%A0%D0%BE%D0%B2%D0%B5%D1%81%D0%BD%D0%B8%D0%BA%22/@50.4059593,24.2814541,13z/data=!4m6!3m5!1s0x4724df6d85e78891:0x178e254007e3d063!8m2!3d50.4120228!4d24.2880737!16s%2Fg%2F11c6cx44zr"
                        target="_blank"
                        rel="noopener noreferrer"
                        title="Перейти до карти"
                    >
                        локація <Location />
                    </a>
                </footer>
            ) : null}
        </>
    );
};

export default Footer;
