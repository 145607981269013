import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Tittle from "../../components/commons/Title/Title";
import { GoBackBtn } from "../../components/commons/GoBackBtn/GoBackBtn";
import css from "./Treatment.module.scss";
import { getOneTreatment } from "../../services/api";

export const OneTreatment = () => {
    const [treatment, setTreatment] = useState(null);
    const { id } = useParams();

    useEffect(() => {
        const editTitle = id.split("-").join(" ");
        getOneTreatment(editTitle).then((data) => {
            if (data.status === 200) {
                setTreatment(data.data);
            }
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            {treatment ? (
                <div className={`${css.oneTreatment} container`}>
                    <GoBackBtn />
                    <div className={css.wrapImg}>
                        <img src={treatment?.image} alt={treatment?.title} />
                    </div>
                    <Tittle text={treatment?.title} />
                    <div
                        className="inner_html"
                        dangerouslySetInnerHTML={{
                            __html: `${treatment?.description ?? ""}`,
                        }}
                    />
                </div>
            ) : null}
        </>
    );
};
