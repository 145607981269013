import React from "react";
import css from "./AddOneItem.module.scss";

import { ReactComponent as Plus } from "../../../assets/images/plus.svg";

const AddOneItem = ({ desription = "" }) => {
    return (
        <div className={css.wrapAddItem}>
            <p>Створити {desription}</p>
            <Plus />
        </div>
    );
};

export default AddOneItem;
