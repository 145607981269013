import React, { useState } from "react";
import { getToken } from "../../services/api";
import { useNavigate } from "react-router-dom";

import css from "./AdminLogin.module.scss";

export const AdminLogin = () => {
    const navigate = useNavigate();
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    const handleEmail = (e) => setEmail(e.target.value);
    const handlePassword = (e) => setPassword(e.target.value);

    const handleSubmit = async (e) => {
        e.preventDefault();
        const isToken = await getToken({ email, password });
        isToken?.data?.token && navigate("/admin");
    };

    return (
        <form className={css.wrapLogin} onSubmit={handleSubmit}>
            <div>
                <label htmlFor="email">Емейл:</label>
                <input
                    type="text"
                    id="email"
                    value={email}
                    onChange={handleEmail}
                />
            </div>
            <div>
                <label htmlFor="password">Пароль:</label>
                <input
                    type="password"
                    id="password"
                    value={password}
                    onChange={handlePassword}
                />
            </div>
            <button type="submit">Ввійти</button>
        </form>
    );
};
