import React from "react";
import { Routes, Route } from "react-router-dom";

import { Root } from "../../pages/Root/Root";
import { AboutUs } from "../../pages/AboutUs/AboutUs";
import { Contacts } from "../../pages/Contacts/Contacts";
import { Media } from "../../pages/Media/Media";
import { News } from "../../pages/News/News";
import { OneNews } from "../../pages/News/OneNews";
import { Recreation } from "../../pages/Recreation/Recreation";
import { Schedule } from "../../pages/Schedule/Schedule";
import { Treatment } from "../../pages/Treatment/Treatment";
import { OneTreatment } from "../../pages/Treatment/OneTreatment";
import { AdminLogin } from "../../pages/AdminLogin/AdminLogin";
import { Admin } from "../../pages/Admin/Admin";
import { AdminAboutUs } from "../../pages/AdminAboutUs/AdminAboutUs";
import { AdminRoot } from "../../pages/AdminRoot/AdminRoot";
import { AdminNews } from "../../pages/AdminNews/AdminNews";
import { AdminOneNews } from "../../pages/AdminNews/AdminOneNews";
import { AdminTreatment } from "../../pages/AdminTreatment/AdminTreatment";
import { AdminOneTreatment } from "../../pages/AdminTreatment/AdminOneTreatment";
import { AdminRecreation } from "../../pages/AdminRecreation/AdminRecreation";
import { AdminMedia } from "../../pages/AdminMedia/AdminMedia";
import { AdminSchedule } from "../../pages/AdminSchedule/AdminSchedule";
import { AdminContacts } from "../../pages/AdminContacts/AdminContacts";

import css from "./Main.module.scss";

const routes = [
    { path: "/", component: <Root /> },
    { path: "/ПРО-НАС", component: <AboutUs /> },
    { path: "/НОВИНИ", component: <News /> },
    { path: "/НОВИНИ/:id", component: <OneNews /> },
    { path: "/ЛІКУВАННЯ", component: <Treatment /> },
    { path: "/ЛІКУВАННЯ/:id", component: <OneTreatment /> },
    { path: "/ВІДПОЧИНОК", component: <Recreation /> },
    { path: "/МЕДІА", component: <Media /> },
    { path: "/ГРАФІК-ЗАЇЗДІВ", component: <Schedule /> },
    { path: "/КОНТАКТИ", component: <Contacts /> },
    { path: "/admin-login", component: <AdminLogin /> },
    {
        path: "/admin",
        component: <Admin />,
        outlet: [
            { path: "/admin", component: <AdminRoot /> },
            { path: "/admin/ПРО-НАС", component: <AdminAboutUs /> },
            { path: "/admin/НОВИНИ", component: <AdminNews /> },
            { path: "/admin/НОВИНИ/:id", component: <AdminOneNews /> },
            { path: "/admin/ЛІКУВАННЯ", component: <AdminTreatment /> },
            { path: "/admin/ЛІКУВАННЯ/:id", component: <AdminOneTreatment /> },
            { path: "/admin/ВІДПОЧИНОК", component: <AdminRecreation /> },
            { path: "/admin/МЕДІА", component: <AdminMedia /> },
            { path: "/admin/ГРАФІК-ЗАЇЗДІВ", component: <AdminSchedule /> },
            { path: "/admin/КОНТАКТИ", component: <AdminContacts /> },
        ],
    },
];

const Main = () => {
    return (
        <div className={css.wrapMain}>
            <Routes>
                {routes.map((route, index) => (
                    <Route
                        key={index}
                        path={route.path}
                        element={route.component}
                    >
                        {route?.outlet
                            ? route.outlet.map((o, idx) => (
                                  <Route
                                      key={idx}
                                      path={o.path}
                                      element={o.component}
                                  />
                              ))
                            : null}
                    </Route>
                ))}
                <Route element={<>ErrorPage</>} />
            </Routes>
        </div>
    );
};

export default Main;
