import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { MyInput } from "../../components/commons/MyInput/MyInput";
import { MyTextarea } from "../../components/commons/MyTextarea/MyTextarea";
import { TextEditor } from "../../components/commons/TextEditor/TextEditor";
import { ImageUploader } from "../../components/commons/ImageUploader/ImageUploader";
import { MyButton } from "../../components/commons/MyButton/MyButton";
import { GoBackBtn } from "../../components/commons/GoBackBtn/GoBackBtn";
import toastMessage from "../../components/commons/Toast/Toast";
import { validateTitle } from "../../utils/validateTitle";

import {
    createNews,
    getOneNews,
    updateNews,
    uploadNewsImage,
} from "../../services/api";

import css from "./AdminNews.module.scss";

const newDefaultNews = {
    description: "",
    image: "",
    short_description: "",
    title: "",
};

export const AdminOneNews = () => {
    const navigate = useNavigate();
    const { id } = useParams();
    const [selectNews, setSelectNews] = useState(null);
    const [selectedFile, setSelectedFile] = useState(null);
    const [image, setImage] = useState(selectNews?.image || "");
    const [isCreate, setsCreate] = useState(false);

    useEffect(() => {
        if (id === "create_new") {
            setsCreate(true);
            setSelectNews(newDefaultNews);
            return;
        }

        const urlId = id.split("-").join(" ");
        getOneNews(urlId).then((data) => {
            setSelectNews(data.data);
            setImage(data.data.image);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleTitle = (e) => {
        const validVal = validateTitle(e.target.value);

        setSelectNews({
            ...selectNews,
            title: validVal,
        });
    };

    const handleImageChange = (e) => {
        const file = e.target.files[0];
        if (!file) return;
        setSelectedFile(file);
        setImage(URL.createObjectURL(file));
    };

    const uploadFile = async () => {
        if (selectedFile) {
            let formData = new FormData();
            formData.append("file", selectedFile);
            const result = await uploadNewsImage(formData);
            if (result.status === 200) return result.data.toString().trim();
        }

        return "";
    };

    const isValidData = () => {
        if (
            selectNews.title === "" ||
            selectNews.short_description === "" ||
            selectNews.description === ""
        ) {
            toastMessage("Заповніть усі поля.", 300);
            return false;
        }
        return true;
    };

    const handleSubmit = () => {
        if (!isValidData()) return;
        uploadFile().then((newFileName) => {
            const newData = {
                id: selectNews.id,
                title: selectNews.title,
                description: selectNews.description,
                details: selectNews.details,
                image: newFileName.length ? newFileName : selectNews.image,
            };

            updateNews(newData).then((data) => {
                if (data) {
                    toastMessage("Інформація успішно оновлена.", data.status);
                    navigate(-1);
                }
            });
        });
    };

    const handleCreate = () => {
        if (!isValidData()) return;
        uploadFile().then((newFileName) => {
            const newData = {
                title: selectNews.title,
                short_description: selectNews.short_description,
                description: selectNews.description,
                image: newFileName.length ? newFileName : selectNews.image,
            };
            createNews(newData).then((data) => {
                if (data) {
                    toastMessage("Новина успішно створене.", data.status);
                    navigate(-1);
                }
            });
        });
    };

    return (
        <>
            {selectNews ? (
                <div className={css.wrapAdminOneNews}>
                    <GoBackBtn />
                    <MyInput
                        value={selectNews.title}
                        onChange={handleTitle}
                        label="Заголовок:"
                    />
                    <MyTextarea
                        value={selectNews.short_description}
                        onChange={(e) => {
                            console.log(selectNews, "selectNews111");
                            setSelectNews({
                                ...selectNews,
                                short_description: e.target.value,
                            });
                        }}
                        label="Короткий опис:"
                    />

                    <TextEditor
                        text={selectNews.description}
                        setText={(text) => {
                            setSelectNews((prevState) => ({
                                ...prevState,
                                description: text,
                            }));
                        }}
                        label="Опис:"
                    />

                    <div>
                        <span>
                            <ImageUploader
                                image={image}
                                handleImageChange={handleImageChange}
                            />
                        </span>
                        <MyButton
                            text="Зберегти"
                            onClick={isCreate ? handleCreate : handleSubmit}
                        />
                    </div>
                </div>
            ) : null}
        </>
    );
};
