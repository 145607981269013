import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Tittle from "../../components/commons/Title/Title";
import { GoBackBtn } from "../../components/commons/GoBackBtn/GoBackBtn";
import css from "./News.module.scss";
import { getOneNews } from "../../services/api";

export const OneNews = () => {
    const [news, setNews] = useState(null);
    const { id } = useParams();

    useEffect(() => {
        const editTitle = id.split("-").join(" ");
        getOneNews(editTitle).then((data) => {
            if (data.status === 200) {
                setNews(data.data);
            }
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            {news ? (
                <div className={`${css.wrapOneNews} container`}>
                    <GoBackBtn />
                    <div className={css.wrapImg}>
                        <img src={news.image} alt={news.title} />
                    </div>
                    <Tittle text={news.title} />
                    <div
                        className="inner_html"
                        dangerouslySetInnerHTML={{ __html: news.description }}
                    />
                </div>
            ) : null}
        </>
    );
};
