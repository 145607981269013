import React from "react";

export const MyInput = ({
    type = "text",
    name,
    value,
    placeholder,
    onChange,
    label,
    required = false,
    maxLength,
    minLength,
    pattern,
    autoComplete = "off",
    className,
    style,
    labelStyle,
    ...rest
}) => {
    return (
        <div
            className={className}
            style={{ display: "flex", flexDirection: "column", ...style }}
        >
            {label && (
                <label
                    htmlFor={name}
                    style={{
                        marginBottom: "8px",
                        fontWeight: "bold",
                        ...labelStyle,
                    }}
                >
                    {label}
                    {required && <span style={{ color: "red" }}>*</span>}
                </label>
            )}
            <input
                type={type}
                id={name}
                name={name}
                value={value}
                placeholder={placeholder}
                onChange={onChange}
                required={required}
                maxLength={maxLength}
                minLength={minLength}
                pattern={pattern}
                autoComplete={autoComplete}
                style={{
                    padding: "8px",
                    borderRadius: "5px",
                    border: "1px solid #ccc",
                    fontSize: "16px",
                    marginBottom: "10px",
                    ...rest,
                }}
            />
        </div>
    );
};
