import React, { useEffect, useState } from "react";

import { getAboutList } from "../../services/api";

import css from "./AdminAboutUs.module.scss";
import AboutItem from "./AboutItem";

export const AdminAboutUs = () => {
    const [about, setAbout] = useState([]);

    useEffect(() => {
        async function getList() {
            const response = await getAboutList();
            setAbout(response?.data?.about ?? []);
        }

        getList();
    }, []);

    return (
        <div className={css.wrapAdminAboutUs}>
            {about.map((a) => (
                <AboutItem key={a.id} data={a} />
            ))}
        </div>
    );
};
