import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import Tittle from "../../components/commons/Title/Title";
import { getNewsList } from "../../services/api";
import truncateTextToWords from "../../utils/truncateTextToWords";
import titleToLink from "../../utils/titleToLink";
import css from "./News.module.scss";

export const News = () => {
    const [news, setNews] = useState(null);

    useEffect(() => {
        getNewsList().then(({ data }) => {
            const sortNews = data.news.sort((a, b) => b.id - a.id);
            setNews(sortNews);
        });
    }, []);

    return (
        <div className={`${css.wrapNews} container`}>
            <Tittle text="Новини" />
            <ul>
                {news?.map((el) => (
                    <li key={el.id}>
                        <NavLink to={`/НОВИНИ${titleToLink(el.title)}`}>
                            <img src={el.image} alt={el.title} />
                            <h3>{el.title}</h3>
                            <p>
                                {truncateTextToWords(el.short_description, 20)}
                            </p>
                            <span>більше</span>
                        </NavLink>
                    </li>
                ))}
            </ul>
        </div>
    );
};
