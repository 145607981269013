import React, { useState, useEffect } from "react";

import { MyButton } from "../../components/commons/MyButton/MyButton";
import { TextEditor } from "../../components/commons/TextEditor/TextEditor";
import toastMessage from "../../components/commons/Toast/Toast";
import { getRecreation, updateRecreation } from "../../services/api";
import css from "./AdminRecreation.module.scss";

export const AdminRecreation = () => {
    const [text, setText] = useState(null);

    useEffect(() => {
        getRecreation()
            .then(({ data }) => {
                setText(data?.[0]?.description ?? null);
            })
            .catch((e) => console.log(e, "error"));
    }, []);

    const handleSubmit = async () => {
        updateRecreation({ description: text })
            .then((data) => {
                if (data) {
                    toastMessage("Дані збережені успішно.", data.status);
                }
            })
            .catch((e) => console.log(e, "error"));
    };

    return (
        <>
            {text ? (
                <div className={css.wrapAdminRecriation}>
                    <TextEditor text={text} setText={setText} />
                    <div>
                        <MyButton text="Зберегти" onClick={handleSubmit} />
                    </div>
                </div>
            ) : null}
        </>
    );
};
