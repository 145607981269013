import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const toastMessage = (message, status) => {
    return toast(`${message}`, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        type: status === 200 ? toast.TYPE.SUCCESS : toast.TYPE.WARNING,
    });
};

export default toastMessage;
