import React from "react";

import LoadNewVideo from "../../components/commons/LoadNewVideo/LoadNewVideo";
import GalleryEditor from "../../components/commons/GalleryEditor/GalleryEditor";

export const AdminMedia = () => {
    return (
        <div>
            <LoadNewVideo />
            <GalleryEditor />
        </div>
    );
};
