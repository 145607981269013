import React, { useState } from "react";
import { NavLink, useLocation } from "react-router-dom";

import css from "./Header.module.scss";
import logo from "../../assets/images/logo_rovesnyk.png";
import { ReactComponent as Menu } from "../../assets/images/icons/menu.svg";
import { ReactComponent as CrissCross } from "../../assets/images/icons/criss-cross.svg";

const routes = [
    { path: "/", name: "Головна" },
    { path: "/ПРО-НАС", name: "Про нас" },
    { path: "/НОВИНИ", name: "Новини" },
    { path: "/ЛІКУВАННЯ", name: "Лікування" },
    { path: "/ВІДПОЧИНОК", name: "Відпочинок" },
    { path: "/МЕДІА", name: "Медіа" },
    { path: "/ГРАФІК-ЗАЇЗДІВ", name: "Графік заїздів" },
    { path: "/КОНТАКТИ", name: "Контакти" },
];

const Header = () => {
    const location = useLocation();
    const [isOpen, setIsOpen] = useState(false);

    return (
        <div className={css.wrapHeader}>
            <NavLink to="/" className={css.wrapLogo}>
                <img src={logo} alt="logo" />
            </NavLink>

            <nav
                className={isOpen ? `${css.menu} ${css.active}` : `${css.menu}`}
            >
                {routes.map((route, idx) => (
                    <span key={idx} onClick={() => setIsOpen(false)}>
                        <NavLink
                            className={
                                decodeURIComponent(location.pathname) ===
                                route.path
                                    ? css.isActive
                                    : ""
                            }
                            to={route.path}
                        >
                            {route.name}
                        </NavLink>
                    </span>
                ))}
            </nav>
            <span onClick={() => setIsOpen(!isOpen)} className={css.mobile_btn}>
                {isOpen ? <CrissCross /> : <Menu />}
            </span>
        </div>
    );
};

export default Header;
