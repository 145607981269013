import React, { useState, useEffect } from "react";

import { getRecreation } from "../../services/api";
import css from "./Recreation.module.scss";

export const Recreation = () => {
    const [recreation, setRecreation] = useState(null);

    useEffect(() => {
        getRecreation().then(({ data }) => {
            setRecreation(data?.[0]?.description);
        });
    }, []);

    return (
        <div className={`${css.wrapRecreation} container`}>
            <div
                className="inner_html"
                dangerouslySetInnerHTML={{ __html: recreation }}
            />
        </div>
    );
};
