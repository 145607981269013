import React from "react";
import { useNavigate } from "react-router-dom";
import { ReactComponent as ArrowLeft } from "../../../assets/images/arrow-l.svg";
import css from "./GoBackBtn.module.scss";

export const GoBackBtn = () => {
    const navigate = useNavigate();

    const handleClick = () => {
        navigate(-1);
    };

    return (
        <button className={css.btn} onClick={handleClick}>
            <ArrowLeft />
            <span>Повернутись назад</span>
        </button>
    );
};
