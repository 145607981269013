import React, { Fragment, useState, useEffect } from "react";

import { ImageUploader } from "../ImageUploader/ImageUploader";

import { MyButton } from "../MyButton/MyButton";
import DeleteBtn from "../DeleteBtn/DeleteBtn";
import toastMessage from "../Toast/Toast";

import {
    getMediaImages,
    uploadMediaImage,
    updateMediaImages,
    deleteMediaImage,
} from "../../../services/api";

import css from "./GalleryEditor.module.scss";

const GalleryEditor = () => {
    const [photos, setPhotos] = useState([]);
    const [selectedFile, setSelectedFile] = useState(null);
    const [image, setImage] = useState(null);

    useEffect(() => {
        getMediaImages().then((data) => {
            setPhotos(data.data.images.sort((a, b) => b.id - a.id));
        });
    }, []);

    const handleImageChange = (e) => {
        const file = e.target.files[0];
        if (!file) return;
        setSelectedFile(file);
        setImage(URL.createObjectURL(file));
    };

    const uploadFile = async () => {
        if (selectedFile) {
            let formData = new FormData();
            formData.append("file", selectedFile);
            const result = await uploadMediaImage(formData);
            if (result.status === 200) return result.data.toString().trim();
        }

        return "";
    };

    const handleSave = () => {
        uploadFile().then((newFileName) => {
            const newData = {
                image: newFileName,
            };

            updateMediaImages(newData).then((data) => {
                if (data) {
                    const newPhotos = [{ ...data.data }, ...photos];
                    toastMessage(
                        "Нове зображення успішно збережене.",
                        data.status
                    );
                    setPhotos(newPhotos);
                    setImage(null);
                    setSelectedFile(null);
                }
            });
        });
    };

    const saveDeleteImg = (id) => {
        deleteMediaImage(id).then((data) => {
            if (data.data === 1) {
                const newPhotos = photos.filter((el) => el.id !== id);
                setPhotos(newPhotos);
            }
        });
    };

    return (
        <div className={css.wrapGalleryEditor}>
            <h4>Галерея:</h4>
            <ul className={css.galleryList}>
                {image ? (
                    <OneImage
                        src={image}
                        alt={`${image}`}
                        handleDelete={() => setImage(null)}
                        handleSave={handleSave}
                    />
                ) : null}

                <li className={css.wrapImageUploader}>
                    <ImageUploader
                        image={null}
                        handleImageChange={handleImageChange}
                        isLabel={false}
                    />
                </li>

                {photos?.map((el) => (
                    <Fragment key={el.id}>
                        <OneImage
                            src={el.image}
                            alt={`${el.image}_${el.id}`}
                            handleDelete={() => saveDeleteImg(el.id)}
                        />
                    </Fragment>
                ))}
            </ul>
        </div>
    );
};

const OneImage = ({ src, alt, handleDelete = null, handleSave = null }) => {
    return (
        <li className={css.wrapOneImage}>
            <img src={src} alt={`${alt}`} />
            <div className={css.wrapBtns}>
                <DeleteBtn onClick={handleDelete} />
                {handleSave ? (
                    <MyButton onClick={handleSave} text="Зберегти" />
                ) : null}
            </div>
        </li>
    );
};

export default GalleryEditor;
