import React from "react";
import { NavLink, useNavigate, useLocation } from "react-router-dom";

import css from "./AdminLeftNavbar.module.scss";
import { MyButton } from "../MyButton/MyButton";
import { logout } from "../../../services/api";

const routes = [
    { path: "/admin", name: "Головна" },
    { path: "/admin/ПРО-НАС", name: "Про нас" },
    { path: "/admin/НОВИНИ", name: "Новини" },
    { path: "/admin/ЛІКУВАННЯ", name: "Лікування" },
    { path: "/admin/ВІДПОЧИНОК", name: "Відпочинок" },
    { path: "/admin/МЕДІА", name: "Медіа" },
    { path: "/admin/ГРАФІК-ЗАЇЗДІВ", name: "Графік заїздів" },
    { path: "/admin/КОНТАКТИ", name: "Контакти" },
];

export const AdminLeftNavbar = () => {
    const navigate = useNavigate();
    const location = useLocation();

    const handleLogout = () => {
        logout();
        navigate("/");
    };

    function isRouteActive(route) {
        // Обрізаємо частину "/admin/" з поточного шляху перед порівнянням з маршрутом
        const trimmedPathname = decodeURIComponent(location.pathname).replace(
            /^\/admin\//,
            ""
        );
        const trimmedRoutesPath = route.path.replace(/^\/admin\//, "");
        // Перевіряємо, чи обрізаний шлях URL дорівнює шляху маршруту
        return trimmedPathname.startsWith(trimmedRoutesPath);
    }
    return (
        <div className={css.wrapAdminLeftNavbar}>
            <nav className={css.nav}>
                {routes.map((route, idx) => (
                    <NavLink
                        key={idx}
                        className={isRouteActive(route) ? css.isActive : ""}
                        to={`${route.path}`}
                    >
                        {route.name}
                    </NavLink>
                ))}
            </nav>
            <h4>Адміністратор</h4>
            <MyButton text="Вийти" onClick={handleLogout} theme="red" />
        </div>
    );
};
