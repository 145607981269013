import React from "react";

import { useContacts } from "../../context/ContactsContext";
import mapUrl from "../../assets/images/rovesnyk_in_map.jpg";

import css from "./Contacts.module.scss";

export const Contacts = () => {
    const contacts = useContacts();

    return (
        <div className={`${css.wrapContacts} container`}>
            <a
                href="https://www.google.com/maps/place/%D0%A1%D0%B0%D0%BD%D0%B0%D1%82%D0%BE%D1%80%D1%96%D0%B9+%22%D0%A0%D0%BE%D0%B2%D0%B5%D1%81%D0%BD%D0%B8%D0%BA%22/@50.4059593,24.2814541,13z/data=!4m6!3m5!1s0x4724df6d85e78891:0x178e254007e3d063!8m2!3d50.4120228!4d24.2880737!16s%2Fg%2F11c6cx44zr"
                target="_blank"
                rel="noopener noreferrer"
                className={css.wrapMap}
                title="Перейти до карти"
            >
                <img src={mapUrl} alt="rovesnyk_in_map" />
            </a>
            {contacts ? (
                <div className={css.wrapLocation}>
                    <div>
                        <h3>Адреса:</h3>
                        <p>{contacts?.address}</p>
                        <p>e-mail: {contacts?.email}</p>
                        <p>тел: {contacts?.phone}</p>
                    </div>
                    <div>
                        <h3>Графік руху автобуса:</h3>
                        <div
                            className="inner_html"
                            dangerouslySetInnerHTML={{
                                __html: contacts?.bus_timetable,
                            }}
                        />
                    </div>
                </div>
            ) : null}
        </div>
    );
};
