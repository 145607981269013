import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import Tittle from "../../components/commons/Title/Title";
import { getTreatmentList } from "../../services/api";
import truncateTextToWords from "../../utils/truncateTextToWords";
import titleToLink from "../../utils/titleToLink";

import css from "./Treatment.module.scss";

export const Treatment = () => {
    const [treatments, setTreatments] = useState(null);

    useEffect(() => {
        getTreatmentList().then(({ data }) => {
            const sortTreatments = data.treatments.sort((a, b) => b.id - a.id);
            setTreatments(sortTreatments);
        });
    }, []);

    return (
        <div className={`${css.wrapTreatment} container`}>
            <Tittle text="Лікування" />
            <div className={css.descr}>
                <p>
                    В ОЛК «Ровесник» розроблені чіткі алгоритми діагностичного,
                    реабілітаційного та лікувального етапів з проблемами
                    опорно-рухового апарату, нервовими розладами та
                    захворюваннями органів дихання.
                </p>
                <p>
                    Заклад забезпечений цілодобовим чергуванням медичного
                    працівника.
                </p>
                <p>
                    Лікувальні процедури в закладі відпускаються щоденно з 8.00
                    до 20.00год. , включаючи вихідні та святкові дні.
                </p>
            </div>

            <ul className={css.treatmentsList}>
                {treatments?.map((el) => (
                    <li key={el.id} className={css.treatment}>
                        <NavLink
                            className={css.linkCard}
                            to={`/ЛІКУВАННЯ${titleToLink(el.title)}`}
                        >
                            <img
                                className={css.treatmentImg}
                                src={el.image}
                                alt={el.title}
                            />
                            <h3 className={css.title}>{el.title}</h3>
                            <p className={css.description}>
                                {truncateTextToWords(el.short_description, 20)}
                            </p>
                            <span>більше</span>
                        </NavLink>
                    </li>
                ))}
            </ul>
        </div>
    );
};
