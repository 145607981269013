function truncateTextToWords(text, wordCount) {
    // Розбиваємо рядок на масив слів
    const words = text.split(" ");

    // Перевіряємо, чи рядок має більше слів, ніж wordCount
    if (words.length > wordCount) {
        // Обрізаємо масив до кількості слів, вказаної параметром wordCount
        const truncatedWords = words.slice(0, wordCount);

        // Повертаємо з'єднаний рядок обрізаних слів з додаванням три крапки в кінці
        return truncatedWords.join(" ") + "...";
    } else {
        // Якщо кількість слів у рядку менше або дорівнює wordCount, повертаємо вихідний рядок
        return text;
    }
}

export default truncateTextToWords;
