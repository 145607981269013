import React, { useState, useEffect } from "react";
import Title from "../../components/commons/Title/Title";

import { getSchedule } from "../../services/api";

import css from "./Schedule.module.scss";

export const Schedule = () => {
    const [schedule, setSchedule] = useState(null);

    useEffect(() => {
        getSchedule().then(({ data }) => {
            setSchedule(data?.[0]?.description);
        });
    }, []);

    return (
        <div className={`${css.wrapSchedule} container`}>
            <Title text="Графіки заїздів:" />
            <div
                className="inner_html"
                dangerouslySetInnerHTML={{ __html: schedule }}
            />
        </div>
    );
};
