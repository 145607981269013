import axios from "../lib/axios";

axios.defaults.headers.post["Content-Type"] = "application/json";
axios.defaults.headers.get["Content-Type"] = "application/json";
axios.defaults.headers.put["Content-Type"] = "application/json";
axios.defaults.headers.patch["Content-Type"] = "application/json";

/*
 * Login Admin
 */

export const getToken = (data) => {
    return axios.post(`/admin/token/obtain`, data);
};
export const logout = () => {
    return axios.put(`/admin/logout`);
};

/*
 * About
 */

export const getAboutList = () => {
    return axios.get(`/admin/about/about`);
};
export const getAbout = (id) => {
    return axios.get(`/admin/about/about/${id}`);
};

// data ===
// {
//   "title": "",
//   "description": "",
//   "image": ""
// }
export const updateAbout = (id, data) => {
    return axios.put(`/admin/about/about/${id}`, data);
};

// NOT use
// export const createAbout = (data) => {
//     return axios.put(`/admin/about/about`, data);
// };
export const deleteAbout = (id) => {
    return axios.delete(`/admin/about/about/${id}`);
};
export const uploadAboutUsImage = (formData) => {
    return axios.post(`/admin/photos/uploadAboutUs`, formData);
};

/*
 * News
 */

export const createNews = (data) => {
    return axios.post(`/admin/news/news`, data);
};
export const getNewsList = () => {
    return axios.get(`/admin/news/news`);
};
export const getOneNews = (id) => {
    return axios.get(`/admin/news/news/${id}`);
};
// data ===
// {
//     "title": "",
//     "description": "",
//     "details": "",
//     "image": ""
// }
export const updateNews = (data) => {
    return axios.put(`/admin/news/news`, data);
};

export const uploadNewsImage = (formData) => {
    return axios.post(`/admin/photos/uploadNews`, formData);
};

export const deleteNews = (id) => {
    return axios.delete(`/admin/news/news/${id}`);
};

/*
 * Treatment
 */

export const createTreatment = (data) => {
    return axios.post(`/admin/treatment/treatment`, data);
};
export const getTreatmentList = () => {
    return axios.get(`/admin/treatment/treatment`);
};
export const getOneTreatment = (id) => {
    return axios.get(`/admin/treatment/treatment/${id}`);
};
// data ===
// {
//     "title": "",
//     "description": "",
//     "image": ""
// }
export const updateTreatment = (data) => {
    return axios.put(`/admin/treatment/treatment`, data);
};

export const uploadTreatmentImage = (formData) => {
    return axios.post(`/admin/photos/uploadTreatment`, formData);
};

export const deleteTreatment = (id) => {
    return axios.delete(`/admin/treatment/treatment/${id}`);
};

/*
 * Recreation
 */

export const getRecreation = () => {
    return axios.get(`/admin/recreation/recreation`);
};
// data ===
// {
//     "text": "<h1>Text</h1>",
// }
export const updateRecreation = (data) => {
    return axios.put(`/admin/recreation/recreation`, data);
};

/*
 * Schedule
 */
export const getSchedule = () => {
    return axios.get(`/admin/schedule/schedule`);
};
// data ===
// {
//     "text": "<h1>Text</h1>",
// }
export const updateSchedule = (data) => {
    return axios.put(`/admin/schedule/schedule`, data);
};

/*
 * Contacts
 */
export const getContacts = () => {
    return axios.get(`/admin/contacts/contacts`);
};
// data ===
// {
// "address": "Україна, Львівська обл., Червоноградcький р-н, с. Комарів вул. Українська, 1А",
// "email": "rovesnyk@bis.net.ua",
// "phone": "067 885 33 40",
// "bus_timetable": <>-8:00 - З Червонограда</>
// "facebookLink": "https://www.facebook.com/profile.php?id=100015446770583"
// }

export const updateContacts = (data) => {
    return axios.put(`/admin/contacts/contacts`, data);
};
/*
 * Media Images
 */
export const getMediaImages = () => {
    return axios.get(`/admin/media/images`);
};
// media = [{
// id: 1,
// image: "/media/123.jpeg"
// }]
export const uploadMediaImage = (formData) => {
    return axios.post(`/admin/photos/uploadMedia`, formData);
};
export const updateMediaImages = (data) => {
    return axios.post(`/admin/media/media`, data);
};
export const deleteMediaImage = (id) => {
    return axios.delete(`/admin/media/media/${id}`, id);
};
/*
 * Media Video
 */
export const getMediaVideo = () => {
    return axios.get(`/admin/media/video`);
};
// videoUrl = ""
export const updateMediaVideo = (url) => {
    return axios.put(`/admin/media/video`, url);
};
