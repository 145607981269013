import { useEffect, useRef } from "react";
import Quill from "quill";

import css from "./TextEditor.module.scss";

const toolbarOptions = [
    [{ header: [1, 2, 3, 4, 5, 6, false] }],
    ["bold", "italic", "underline"], // toggled buttons
    ["blockquote"], //"code-block"
    ["link"],

    [{ list: "ordered" }, { list: "bullet" }],
    [{ align: ["", "right", "center", "justify"] }],
    [{ size: ["small", false, "large", "huge"] }], // custom dropdown

    [{ color: [] }, { background: [] }], // dropdown with defaults from theme
    [{ font: [] }],
];

export const TextEditor = ({ setText = () => {}, text = "", label = "" }) => {
    const editorRef = useRef(null);

    useEffect(() => {
        const quill = new Quill(editorRef.current, {
            theme: "snow",
            modules: {
                toolbar: toolbarOptions,
            },
        });
        // Set text from props
        quill.root.innerHTML = text;
        // Detect changes
        quill.on("text-change", () => {
            let content = quill.root.innerHTML;
            setText(content);
        });
        return () => {
            quill.off("text-change");
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // Rendering the QuillEditor component with a reference to the DOM element
    return (
        <div className={css.wrapTextEditor}>
            {label && <label>{label}</label>}
            <div ref={editorRef} />
        </div>
    );
};
