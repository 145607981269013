import React from "react";

import css from "./Root.module.scss";

export const Root = () => {
    return (
        <div className={css.wrapRoot}>
            <div className={css.bg} />
            <p className="container">
                Справжній <span>·</span> Відпочинок
            </p>
        </div>
    );
};
