import React, { useState, useEffect, Fragment } from "react";
import { NavLink } from "react-router-dom";
import DeletableContentCard from "../../components/commons/DeletableContentCard/DeletableContentCard";
import AddOneItem from "../../components/commons/AddOneItem/AddOneItem";
import toastMessage from "../../components/commons/Toast/Toast";
import { getNewsList, deleteNews } from "../../services/api";

import css from "./AdminNews.module.scss";

const rootUrl = "/admin/НОВИНИ";

export const AdminNews = () => {
    const [news, setNews] = useState(null);

    useEffect(() => {
        getNewsList()
            .then(({ data }) => {
                setNews(data.news);
            })
            .catch((e) => console.log(e, "error"));
    }, []);

    const handleDelete = (id) => {
        deleteNews(id).then((data) => {
            if (data.status === 200) {
                setNews(news.filter((el) => el.id !== id));
                toastMessage("Видалення пройшло успішно.", data.status);
            }
        });
    };

    return (
        <div className={css.wrapAdminNews}>
            <NavLink to={`${rootUrl}/create_new`}>
                <AddOneItem desription="новину" />
            </NavLink>
            {news ? (
                <ul className={css.wrapNewsList}>
                    {news
                        .sort((a, b) => b.id - a.id)
                        .map((n, idx) => (
                            <Fragment key={idx}>
                                <DeletableContentCard
                                    item={n}
                                    rootUrl={rootUrl}
                                    handleDelete={handleDelete}
                                />
                            </Fragment>
                        ))}
                </ul>
            ) : null}
        </div>
    );
};
