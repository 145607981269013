import React from "react";
import { Outlet } from "react-router-dom";
import { AdminLeftNavbar } from "../../components/commons/AdminLeftNavbar/AdminLeftNavbar";

import css from "./Admin.module.scss";

export const Admin = () => {
    return (
        <div className={css.wrapAdmin}>
            <AdminLeftNavbar />
            <Outlet />
        </div>
    );
};
