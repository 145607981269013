import React, { useState } from "react";
import { MyInput } from "../../components/commons/MyInput/MyInput";
import { MyTextarea } from "../../components/commons/MyTextarea/MyTextarea";
import { ImageUploader } from "../../components/commons/ImageUploader/ImageUploader";
import { MyButton } from "../../components/commons/MyButton/MyButton";
import toastMessage from "../../components/commons/Toast/Toast";
import { updateAbout, uploadAboutUsImage } from "../../services/api";

const AboutItem = ({ data: a }) => {
    const [id] = useState(a.id);
    const [title, setTitle] = useState(a.title);
    const [description, setDescription] = useState(a.description);
    const [selectedFile, setSelectedFile] = useState(null);
    const [image, setImage] = useState(a.image);

    const handleSubmit = (id) => {
        uploadFile().then((newFileName) => {
            const data = {
                id: id,
                title: title,
                description: description,
                image: newFileName.length ? newFileName : a.image,
            };

            updateAbout(id, data)
                .then((data) => {
                    if (data) {
                        toastMessage("Дані збережені успішно.", data.status);
                    }
                })
                .catch((e) => console.log(e, "error"));
        });
    };

    const uploadFile = async () => {
        if (selectedFile) {
            let formData = new FormData();
            formData.append("file", selectedFile);
            const result = await uploadAboutUsImage(formData);
            if (result.status === 200) return result.data.toString().trim();
        }

        return "";
    };

    const handleImageChange = (e) => {
        const file = e.target.files[0];
        if (!file) return;
        setSelectedFile(file);
        setImage(URL.createObjectURL(file));
    };

    return (
        <div>
            <MyInput
                value={title}
                label="Заголовок"
                onChange={(e) => {
                    setTitle(e.target.value);
                }}
            />
            <MyTextarea
                value={description}
                label="Опис"
                onChange={(e) => {
                    setDescription(e.target.value);
                }}
            />

            <div>
                <span>
                    <ImageUploader
                        image={image}
                        handleImageChange={handleImageChange}
                    />
                </span>
                <MyButton text="Зберегти" onClick={() => handleSubmit(id)} />
            </div>
        </div>
    );
};

export default AboutItem;
