import toastMessage from "../components/commons/Toast/Toast";
const axiosRequest = require("axios");
const utf8 = require("utf8");

class HttpError extends Error {
    constructor({ response }) {
        super("HttpError");
        this.response = response;
    }
}

const errorHandler = function (err) {
    if (err instanceof HttpError) {
        if (err.response.status >= 400 && err.response.status <= 500) {
            toastMessage(
                `Щось пішло не так ${err.response.status}`,
                err.response.status
            );
        }
        // console.log(err)
    }

    throw err;
};

const axios = axiosRequest.create({
    headers: {
        "X-Requested-With": "XMLHttpRequest",
    },
    baseURL:
        process.env.NODE_ENV === "development"
            ? "http://localhost:4200"
            : "https://rovesnyk.com.ua",
    withCredentials: true,
});

axios.interceptors.request.use((config) => {
    return config;
});

axios.interceptors.response.use(
    function (response) {
        // Any status code that lie within the range of 2xx cause this function to trigger
        // Do something with response data
        return response;
    },
    function (error) {
        // Any status codes that falls outside the range of 2xx cause this function to trigger
        // Do something with response error
        // console.log(error.response, "AXIOS ERROR");

        if (error.response.status === 401) {
            console.log("401: ", error.response, "REDIRECT");
            window.location = "/admin-login";
        }

        if (error.response.statusText) {
            error.response.statusText = utf8.decode(error.response.statusText);
        }
        const j = error.toJSON();
        error.status = j.status;
        error.message = j.message;
        // error.name = j.name; // TODO буде юзатись, можна розкоментувати
        // error.stack = j.stack;

        errorHandler(new HttpError(error));
    }
);

export default axios;
