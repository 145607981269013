import React, { useState, useRef, useEffect } from "react";

import css from "./MyTextarea.module.scss";

export const MyTextarea = ({ label, name, value, onChange, ...rest }) => {
    const textareaRef = useRef(null);
    const [textareaHeight, setTextareaHeight] = useState("auto");

    useEffect(() => {
        setTextareaHeight(`${textareaRef.current.scrollHeight + 10}px`);
    }, []);

    const handleChange = (event) => {
        const { target } = event;
        target.style.height = "auto"; // reset height
        target.style.height = `${target.scrollHeight + 10}px`;
        setTextareaHeight(`${target.scrollHeight + 10}px`);

        if (onChange) {
            onChange(event);
        }
    };

    return (
        <div className={css.wrapTextarea}>
            <label htmlFor={name}>{label}</label>
            <textarea
                ref={textareaRef}
                value={value}
                onChange={handleChange}
                style={{ height: textareaHeight }}
                {...rest}
            />
        </div>
    );
};
