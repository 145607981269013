import React, { useState, useEffect } from "react";
import { MyInput } from "../MyInput/MyInput";
import { MyButton } from "../MyButton/MyButton";
import { YouTubeFrame } from "../../../pages/Media/Media";
import toastMessage from "../Toast/Toast";
import { getMediaVideo, updateMediaVideo } from "../../../services/api.js";

import css from "./LoadNewVideo.module.scss";

const ERROR = `Перевірте правельнісь копійованого лінку з YouTube
    АБО Натисніть кнопку ⬆⬆⬆ Згенерувати нову лінк для відео`;

const TMP_URL = "https://www.youtube.com/watch?v=";
const EMBED_URL = "https://www.youtube.com/embed/";

const INFO = [
    "Інструкція загрузити нового відео з Youtube:",
    "1. Йдем на Youtube.",
    "2. Копіюєм повністю лінк з ютуб відео (має починатись з https://www.youtube.com/watch?v= ...).",
    "3. Вставляєм в поле вводу лінк свого відео.",
    "4. Натискаєм кнопку 'Згенерувати нову лінку для відео' (новий лінк має починатись з https://www.youtube.com/embed/).",
    "5. Після генерації нове відео має появитись з правої сторони, натискаєм кнопку 'Зберегти'.",
    "6. Готово.",
];

function validateNewUrl(url) {
    const valid = url.includes(TMP_URL);
    return valid;
}

const LoadNewVideo = () => {
    const [videoUrl, setVideoUrl] = useState("");
    const [isError, setIsError] = useState(false);

    useEffect(() => {
        getMediaVideo().then(({ data }) => {
            setVideoUrl(data.youtube_url);
        });
    }, []);

    useEffect(() => {
        if (isError) {
            setTimeout(() => {
                setIsError(false);
            }, 5000);
        }
    }, [isError]);

    const handleSubmit = () => {
        if (!videoUrl.includes(EMBED_URL)) {
            setIsError(true);
        } else {
            const newUrl = { youtube_url: videoUrl };
            updateMediaVideo(newUrl).then((data) => {
                toastMessage("Нове відео успішно збережене.", data.status);
            });
        }
    };

    const changeUrl = (e) => {
        e.preventDefault();
        setVideoUrl(e.target.value);
    };

    const generateVideoUrl = () => {
        const isValid = validateNewUrl(videoUrl);

        if (!isValid) {
            setIsError(true);
        } else {
            const tmpUrl = parseAndModifyUrl(videoUrl);
            setVideoUrl(tmpUrl);
        }
    };

    return (
        <div className={css.wrapVideoEditor}>
            <div>
                <div className={css.wrapVideoChange}>
                    <MyInput
                        value={videoUrl}
                        onChange={changeUrl}
                        label="Відео лінк:"
                    />

                    <div>
                        <MyButton
                            text="Згенерувати нову лінк для відео"
                            onClick={() => generateVideoUrl()}
                            theme="grey"
                        />
                        {INFO.map((el, idx) => (
                            <p key={idx}>{el}</p>
                        ))}
                        {isError ? <p className={css.error}>{ERROR}</p> : null}
                    </div>
                </div>
                <YouTubeFrame link={videoUrl} />
            </div>
            <div>
                <MyButton text="Зберегти" onClick={handleSubmit} />
            </div>
        </div>
    );
};

export default LoadNewVideo;

function parseAndModifyUrl(url) {
    // Розбираємо URL з допомогою об'єкту URL
    let urlObject;
    try {
        urlObject = new URL(url);
    } catch (error) {
        return null;
    }

    // Видобуваємо параметр "v" з URL
    const searchParams = new URLSearchParams(urlObject.search);
    const videoId = searchParams.get("v");
    if (videoId === null) {
        return null;
    }
    // Створюємо новий URL
    const newUrl = `https://www.youtube.com/embed/${videoId}`;

    return newUrl;
}
