import React from "react";
import css from "./MyButton.module.scss";

export const MyButton = ({
    text,
    onClick,
    styles,
    theme = "green",
    icon,
    // disabled = false,
}) => {
    return (
        <button
            className={`${css.btn} ${css[theme]}`}
            style={styles}
            onClick={onClick}
            // disabled={disabled}
        >
            {icon ? icon : text}
        </button>
    );
};
