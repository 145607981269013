import React, { useEffect, useState } from "react";
import Title from "../../components/commons/Title/Title";
import { getAboutList } from "../../services/api";
import css from "./AboutUs.module.scss";

export const AboutUs = () => {
    const [aboutUs, setAboutUs] = useState(null);

    useEffect(() => {
        getAboutList().then(({ data }) => {
            setAboutUs(data.about);
        });
    }, []);

    return (
        <>
            {aboutUs ? (
                <div className={`${css.wrapAboutUs} container`}>
                    <Title text="Про нас" />
                    {aboutUs.map((el, idx) => (
                        <div key={idx} className={css.wrapInfo}>
                            <div className={css.wrapDescr}>
                                <h3>{el.title}</h3>
                                <p>{el.description}</p>
                            </div>
                            <div className={css.wrapImg}>
                                <img src={el.image} alt="about us" />
                            </div>
                        </div>
                    ))}
                </div>
            ) : null}
        </>
    );
};
