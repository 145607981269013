import React from "react";

export const AdminRoot = () => {
    return (
        <div>
            <p>
                <em>
                    Адмін панель дозволяє вам легко і зручно редагувати дані для
                    вашого сайту.
                </em>
            </p>
            <p>
                <em>Основні особливості адмін панелі включають:</em>
            </p>
            <p>
                <br />
            </p>
            <ol>
                <li>
                    <strong>Лівий бар навігації:</strong> Адмін панель має
                    зручний лівий бар навігації, який містить вкладки або пункти
                    меню для різних розділів вашого сайту. Ви можете легко
                    переходити між цими вкладками, щоб зайти в потрібний розділ
                    для редагування відповідних даних.
                </li>
                <li>
                    <strong>Основна частина для редагування:</strong> У
                    центральній частині адмін панелі розташована основна
                    область, де ви можете виконувати редагування даних. Це може
                    бути форма редагування, текстовий редактор або інше зручне
                    середовище для зміни вмісту вашого сайту.
                </li>
                <li>
                    <strong>Редагування вмісту:</strong> Адмін панель дозволяє
                    вам редагувати різні елементи вмісту на вашому сайті, такі
                    як текстові блоки, зображення, контактні дані тощо. Ви
                    можете змінювати текст, додавати форматування, завантажувати
                    нові зображення і виконувати багато інших операцій, щоб
                    забезпечити актуальну і привабливу інформацію для вашої
                    аудиторії.
                </li>
                <li>
                    <strong>Збереження змін: </strong>Після внесення необхідних
                    змін ви можете зберегти їх, щоб вони негайно відобразилися
                    на вашому сайті.
                </li>
                <li>
                    <strong>Безпека:</strong>
                    <span>
                        Адмін панель забезпечує механізми безпеки , щоб
                        захистити ваш сайт і дані від несанкціонованого доступу
                        не надавайте логін і пароль недовіреним особам).
                    </span>
                </li>
            </ol>
        </div>
    );
};
