import React from "react";
import { MyButton } from "../MyButton/MyButton";
import { ReactComponent as Trash } from "../../../assets/images/trash.svg";
import css from "./DeleteBtn.module.scss";

const DeleteBtn = ({ onClick = null }) => {
    return (
        <span className={css.wrapBtn}>
            <MyButton icon={<Trash />} onClick={onClick} theme="red" />
        </span>
    );
};

export default DeleteBtn;
